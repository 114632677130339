import React, { useState } from 'react'
import {
    Breadcrumbs,
    Link as MLink,
    Tab,
    Tabs,
    Paper,
    Typography
} from '@material-ui/core'

import UserDetails from './UserDetails'
import ChangePassword from './ChangePassword'

const Profile = () => {
    const [value, setValue] = useState(0)
    const [content, setContent] = useState(<UserDetails />)

    const handleChange = (event, newValue) => {
        const content = (newValue === 0) ? <UserDetails /> : <ChangePassword />
        setContent(content)
        setValue(newValue)
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <Typography color="textPrimary">Profile</Typography>
            </Breadcrumbs>
            <br />
            <Paper square >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Personal Information" />
                    <Tab label="Change Password" />
                </Tabs>
            </Paper>
            {content}
        </div>
    )
}

export default Profile
