import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import mainLogoLight from '../../assets/images/logo/main-logo-light.png'
import mainLogoDark from '../../assets/images/logo/main-logo-dark.png'
import {
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'

import isEmail from 'validator/lib/isEmail'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { URL } from '../../config'
import axios from 'axios'
import { useSnackbar } from 'notistack'


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
                Image To Text Converter&nbsp;
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ForgotPassword(props) {
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const [disabled, setDisabled] = useState(false)
    const [email, setEmail] = useState(null)
    const [otp, setOtp] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [cnfNewPassword, setCnfNewPassword] = useState(null)
    const [status, setStatus] = useState(false)

    const sendNotification = (message, variant = 'success') => {
        const options = { 
            variant
        }
        enqueueSnackbar(message, options);
    }

    useEffect(() => {
        const token = localStorage.getItem('oeToken')
        if (token !== null) {
            var location = '/dashboard'
            props.history.push(location)
        }
        // eslint-disable-next-line
    }, [])

    const emailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const getOtp = (email) => {
        setDisabled(true)
        if (!isEmail(email)) {
            const message = 'Invalid email.'
            sendNotification(message, 'error')
            setDisabled(false)
        } else {
            const data = {
                email
            }
            const url = URL + '/forgot-password'
            axios.post(url, data).then(response => {
                setStatus(true)
                setDisabled(false)
            }).catch(error => {
                var message = 'Try after some time.'
                if (error.response) {
                    message = error.response.data.detail
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                setDisabled(false)
                sendNotification(message, 'error')
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        getOtp(email)
    }

    const handleReset = (e) => {
        e.preventDefault()
        if (newPassword !== cnfNewPassword) {
            const message = 'Passwords are not same.'
            sendNotification(message, 'error')
        }
        else {
            setDisabled(true)
            const url = URL + '/confirm-forgot-password'
            const data = {
                email,
                otp,
                password: newPassword
            }
            axios.post(url, data).then(response => {
                setDisabled(false)
                props.history.push('/login')
            }).catch(error => {
                var message = 'Try after some time.'
                if (error.response) {
                    message = error.response.data.detail
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request);
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log('Error', error.message);
                }
                setDisabled(false)
                sendNotification(message, 'error')
            })
        }
    }

    const otpChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setOtp(value)
    }

    const newPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNewPassword(value)
    }

    const cnfNewPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setCnfNewPassword(value)
    }

    const checkEmailForm = (
        <form id="verify" className={classes.form} onSubmit={handleSubmit} >
            <TextField
                key="email"
                required
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                variant="outlined"
                inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                onChange={emailChange}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
            >
                Submit
            </Button>
            <Grid container>
                <Grid item xs>
                    <MLink href="/#/login" variant="body2" color="textSecondary">
                        Login
                    </MLink>
                </Grid>
            </Grid>
        </form>
    )

    const resetPasswordForm = (
        <form id="reset" className={classes.form} onSubmit={handleReset} >
            <TextField
                key="otp"
                required
                margin="normal"
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                variant="outlined"
                inputProps={{ minLength: 6, maxLength: 6, title: "Numbers only", pattern: "[0-9]{6}" }}
                onChange={otpChange}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                variant="outlined"
                inputProps={{ minLength: 6, maxLength: 50 }}
                onChange={newPasswordChange}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password"
                variant="outlined"
                inputProps={{ minLength: 6, maxLength: 50 }}
                onChange={cnfNewPasswordChange}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
            >
                Submit
            </Button>
            <Grid container>
                <Grid item xs>
                    <MLink href="/#/login" variant="body2" color="textSecondary">
                        Login
                    </MLink>
                </Grid>
            </Grid>
        </form>
    )

    const logo = (props.darkState) ? mainLogoDark : mainLogoLight

    const progress = disabled ? (<LinearProgress />) : ('')

    return (
        <div>
            {progress}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt="OCR Converter Logo" />
                    <div className="mt-5 auth-box">
                        <Typography component="h1" variant="h5">
                            Forgot Password
                        </Typography>
                        {status ? resetPasswordForm : checkEmailForm}
                    </div>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
