import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@material-ui/core'

import ListIcon from '@material-ui/icons/List'
import HomeIcon from '@material-ui/icons/Home'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SettingsIcon from '@material-ui/icons/Settings'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PersonIcon from '@material-ui/icons/Person'
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'

import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../config'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    sideLinks: {
        textDecoration: 'none',
        color: 'unset',
    }
}));

const SideNav = () => {
    const token = localStorage.getItem('oeToken')
    const theme = useTheme()
    const classes = useStyles()
    const [settingToggle, setSettingToggle] = useState(false)
    const [operations, setOperations] = useState([])
    // const [menu, setMenu] = useState([])

    const getOperations = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/operations'
        axios.get(url, headerConfig).then(response => {
            setOperations(response.data.operations)
            // setMenu(response.data.menu)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
        })
    }

    useEffect(() => {
        getOperations()
        // eslint-disable-next-line
    }, [])

    const checkOperation = (item) => {
        const status = operations.includes(item)
        return status
    }

    // const checkMenu = (operation) => {
    //     const status = menu.includes(operation)
    //     return status
    // }

    const color = (theme.palette.type === 'light') ? "primary" : ""

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <Link to="/dashboard" className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Dashboard"><HomeIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>

            { checkOperation('Image Scan') ? (
                <Link to='/dashboard/scan/image' className={classes.sideLinks}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Image Scan"><ImageSearchIcon color={color} /></Tooltip></ListItemIcon>
                        <ListItemText primary="Image Scan" />
                    </ListItem>
                </Link>
            ) : ('')}

            { checkOperation('List Scan') ? (
                <Link to='/dashboard/batch' className={classes.sideLinks}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Scan List"><ViewHeadlineIcon color={color} /></Tooltip></ListItemIcon>
                        <ListItemText primary="Scan List" />
                    </ListItem>
                </Link>
            ) : ('')}

            <ListItem button onClick={() => setSettingToggle(!settingToggle)}>
                <ListItemIcon>
                    <Tooltip title="Settings"><SettingsIcon color={color} /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {settingToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {checkOperation('List Users') ? (
                        <Link to='/dashboard/settings/users' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Users"><SupervisorAccountIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Roles') ? (
                        <Link to='/dashboard/settings/roles' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Roles"><ListIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Roles" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    <Link to='/dashboard/profile' className={classes.sideLinks}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <Tooltip title="My Profile"><PersonIcon color={color} /></Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </ListItem>
                    </Link>
                </List>
            </Collapse>
        </List>
    )
}

export default SideNav
