import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    FormControlLabel,
    FormGroup,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSnackbar } from 'notistack'

const AddRole = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('oeToken')
    const [name, setName] = useState(null)
    const [operations, setOperations] = useState([])
    const [state, setState] = useState({})

    const sendNotification = (message, variant = 'success') => {
        const options = { 
            variant
        }
        enqueueSnackbar(message, options);
    }

    const getOperations = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/operations/all'
        axios.get(url, headerConfig).then(response => {
            setOperations(response.data)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
        })
    }

    useEffect(() => {
        getOperations()
        // eslint-disable-next-line
    }, [])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState({ ...state, [name]: value })
    }

    const nameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        var operations = []
        for (var key in state) {
            if (state[key] === true) {
                operations.push(key)
            }
        }
        if (operations.length === 0) {
            const message = 'Select at least one operation.'
            sendNotification(message, 'error')
        }
        else {
            var _name = name
            _name = ltrim(_name)
            _name = rtrim(_name)
            const data = {
                name: _name,
                operations
            }
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = URL + '/roles'
            axios.post(url, data, headerConfig).then(response => {
                const message = 'Role added.'
                sendNotification(message)
                props.history.push('/dashboard/settings/roles')
            }).catch(error => {
                var message = 'Try after some time.'
                if (error.response) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    }
                    else if (error.response.status === 409) {
                        message = error.response.data.detail
                    }
                    else {
                        message = error.response.data.detail[0].msg
                    }
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                sendNotification(message, 'error')
            })
        }

    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/settings/roles">
                    Roles
                </MLink>
                <Typography color="textPrimary">Add Role</Typography>
            </Breadcrumbs>
            <br />
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit} className="mt-5">
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label="Role Name"
                                    name="name"
                                    variant="outlined"
                                    inputProps={{ minLength: 2, maxLength: 50 }}
                                    onChange={nameChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {operations.map((operation, index) => {
                                    return (
                                        <Accordion id={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={operation.id}
                                                id={operation.id}
                                            >
                                                <Typography>{operation.name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid item xs={12}>
                                                    <FormGroup row>
                                                        {
                                                            operation.operations.map((child, index) => {
                                                                return (
                                                                    <Grid key={index} item xs={2}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <div>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={child.id}
                                                                                        checked={state[child.id]}
                                                                                        onChange={handleInputChange}
                                                                                    />
                                                                                &nbsp;
                                                                                </div>
                                                                            }
                                                                            label={child.name}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </FormGroup>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/roles">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Back
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddRole
