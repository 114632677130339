import React, { useState, useEffect } from 'react'
import {
    AppBar,
    Avatar,
    Button,
    Breadcrumbs,
    
    Chip,
    IconButton,
    Toolbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Link as MLink,
    LinearProgress,
    Tooltip,
    Slide,
    Typography,
    ListItemText,
    ListItem,
    List
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import { URL } from '../../config'
import ImageIcon from '@material-ui/icons/Image'
import DeleteIcon from '@material-ui/icons/Delete'
// import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import CloseIcon from '@material-ui/icons/Close';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ListScanResults = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('oeToken')
    const batchId = props.match.params.batchId
    const classes = useStyles();
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [openContent, setOpenContent] = useState(false);
    const [content, setContent] = useState(null)

    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [loading, setLoading] = useState(false)

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "path",
            label: "Image",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const item = <Avatar variant="square" style={{ width: '100px', height: '100px' }} alt="Preview" src={value} />
                    return item
                }
            }
        },
        {
            name: "number",
            label: "Batch Number",
            options: {
                sort: false,
            }
        },
        {
            name: "name",
            label: "File Name",
            options: {
                sort: true,
            }
        },
        {
            name: "content",
            label: "Content",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value.length > 30) {
                        var str = value.substring(0, 30) + '...'
                        const cell = (
                            <span title={value}>{str}</span>
                        )
                        return cell
                    } else {
                        return value
                    }
                }
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let status = ''
                    if (value === 'Scan') {
                        status = <Chip label="Scan failed" style={{ backgroundColor: "#F9DFDF", color: "red" }} />
                    } else if (value === 'Upload') {
                        status = <Chip label="Upload failed" style={{ backgroundColor: "#F9DFDF", color: "red" }} />
                    } else {
                        status = <Chip label="Success" style={{ backgroundColor: "#E2FAED", color: "green" }} />
                    }
                    return status
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = URL + '/batch/' + batchId
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                var action = ''
                action = (
                    <div>
                        <Tooltip title="View Content">
                            <IconButton onClick={() => {
                                setContent(item.content)
                                handleClickOpenContent()
                            }}>
                                <TextFieldsIcon />
                            </IconButton>
                        </Tooltip>
                        <MLink href={item.path} target="_blank">
                            <Tooltip title="View Image">
                                <IconButton>
                                    <ImageIcon />
                                </IconButton>
                            </Tooltip>
                        </MLink>
                        <Tooltip title="Delete Result">
                                <IconButton onClick={() => handleClickOpen(item.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                    </div>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteItem = (itemId) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/batch/' + batchId  + '/' + itemId
        axios.delete(url, headerConfig).then(response => {
            const message = 'Result deleted.'
            sendNotification(message)
            setOpen(false)
            setLoading(false)
            getList()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            sendNotification(message, 'error')
            setLoading(false)
        })
    }

    const handleClickOpen = (itemId) => {
        setOpen(true)
        setItemId(itemId)
    }

    const handleClickOpenContent = () => {
        setOpenContent(true);
    };

    const handleClose = () => {
        setOpenContent(false);
        setOpen(false)
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(content)
        const message = 'Copied to clipboard.'
        sendNotification(message)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/batch">
                            Scan List
                        </MLink>
                        <Typography color="textPrimary">Scan Results</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Dialog fullScreen open={openContent} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Content
                        </Typography>
                        <Button autoFocus variant="contained" onClick={copyToClipboard}>
                            Copy
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem >
                        <ListItemText primary={content} />
                    </ListItem>
                </List>
            </Dialog>
            <Dialog
            open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {progress}
                <DialogTitle id="alert-dialog-title">Delete Result</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Result ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus disabled={loading}>
                        Close
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} variant="outlined" disabled={loading}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {progress}
            <Table
                title="List Scans Results"
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default ListScanResults
