import React from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
} from '@material-ui/core';

const TotalWidgetCard = (props) => {
    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            {props.title}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {props.body}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TotalWidgetCard
