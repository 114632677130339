import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Button,
    Grid,
    TextField,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { URL } from '../../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useSnackbar } from 'notistack'

const ChangePassword = () => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('oeToken')
    const [oldPassword, setOldPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [disabled, setDisabled] = useState(false)

    const sendNotification = (message, variant = 'success') => {
        const options = { 
            variant
        }
        enqueueSnackbar(message, options);
    }

    const handleOldPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setOldPassword(value)
    }

    const handleNewPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNewPassword(value)
    }

    const handleConfirmPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setConfirmPassword(value)
    }

    const onPasswordUpdate = (e) => {
        e.preventDefault()
        if (newPassword !== confirmPassword) {
            const message = 'Passwords are not same.'
            sendNotification(message, 'error')
        }
        else {
            setDisabled(true)
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = URL + '/change-password'
            const data = {
                old_password: oldPassword,
                new_password: newPassword
            }
            axios.put(url, data, headerConfig).then(response => {
                const message = 'Password changed.'
                document.getElementById('pass').reset(); 
                sendNotification(message)
                setDisabled(false)
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    } else if (error.response.status === 403) {
                        message = error.response.data.detail
                    } else {
                        (process.env.NODE_ENV !== 'production') && console.log(error.response)
                    }
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error.message)
                }
                setDisabled(false)
                sendNotification(message, 'error')
            })
        }
    }

    return (
        <div>
            <Card>
                <CardContent>
                    <form id="pass" name="pass" onSubmit={onPasswordUpdate}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3} className="mt-5">
                                <TextField
                                    key="oldPassword"
                                    required
                                    fullWidth
                                    name="oldPassword"
                                    variant="outlined"
                                    label="Old Password"
                                    type="password"
                                    id="oldPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleOldPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="newPassword"
                                    required
                                    fullWidth
                                    name="newPassword"
                                    variant="outlined"
                                    label="New Password"
                                    type="password"
                                    id="newPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleNewPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="confirmPassword"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    variant="outlined"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleConfirmPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/users">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default ChangePassword
