import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Grid,
    LinearProgress,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import { URL } from '../config'
import TotalWidgetCard from '../components/TotalWidgetCard'
import ListRecentScanResults from './scans/ListRecentScanResults'

const Dashboard = () => {
    const token = localStorage.getItem('oeToken')
    const [widgets, setWidgets] = useState({
        users: 0,
        scans: 0
    })
    const [loading, setLoading] = useState(false)

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = URL + '/dashboard'
        axios.get(url, headerConfig).then(response => {
            setWidgets(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response)
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Dashboard</Typography>
            </Breadcrumbs>
            <br />
            {progress}
            <Grid item xs={12}>
                <Grid container justify="center" spacing="3">
                    <Grid item>
                        <TotalWidgetCard title="Today's Scans" body={widgets.scans} />
                    </Grid>
                    <Grid item>
                        <TotalWidgetCard title="Total Users" body={widgets.users} />
                    </Grid>
                </Grid>
            </Grid>
            <ListRecentScanResults />
        </div>
    )
}

export default Dashboard
