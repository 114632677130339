import React from 'react'
import {
    Breadcrumbs,
    Link as MLink,
    Typography
} from '@material-ui/core'

const Unauthorized = () => {
    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <Typography color="textPrimary">Unauthorized</Typography>
            </Breadcrumbs>
            <br />
            <Typography color="textPrimary">You do not have the permission to view this page. Go back to <MLink color="inherit" href="/#/dashboard">
                Dashboard
                </MLink>.</Typography>
        </div>
    )
}

export default Unauthorized
