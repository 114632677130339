import React, { useState } from 'react'
import {
    Breadcrumbs,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography, Button
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'

import languages from '../../languages.json'
import axios from 'axios'
import { URL } from '../../config'

const ImageScan = () => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('oeToken')
    const [language, setLanguage] = useState(languages[22])
    // const [converted, setConverted] = useState(false)
    // const [text, setText] = useState(null)
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0)
    // const [buffer, setBuffer] = useState(0)

    const sendNotification = (message, variant = 'success') => {
        const options = {
            variant
        }
        enqueueSnackbar(message, options);
    }

    // const copyToClipboard = () => {
    //     navigator.clipboard.writeText(text)
    //     const message = 'Copied to clipboard.'
    //     sendNotification(message)
    // }

    const handleFilesChange = (files) => {
        setFiles(files)
    }

    const submit = () => {
        setLoading(true)

        if (files.length === 0) {
            const message = 'Select at least 1 file.'
            sendNotification(message, 'error')
            setLoading(false)
            setProgress(0)
        } else {
            const headerConfig = {
                headers: {
                    // 'accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    token
                },
                onUploadProgress: progressEvent => {
                    const value = progressEvent.loaded
                    const total = progressEvent.total
                    const percentage = (value * 100) / total
                    setProgress(percentage)
                    // const diff2 = Math.random() * 10;
                    // setBuffer(progress + percentage + diff2);
                    // console.log(progressEvent.loaded, progressEvent.total)
                }
            }

            var formData = new FormData();
            console.log(language.value);
            formData.append('language', language.value);
            files.map(file => {
                formData.append('files', file);
                return true
            })

            const url = URL + '/batch'
            axios.post(url, formData, headerConfig).then(response => {
                setLoading(false)
                setProgress(0)
                const message = 'Processing files.'
                sendNotification(message)
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    if (error.response.status === 400) {
                        message = error.response.data.detail
                    }
                    (process.env.NODE_ENV !== 'production') && console.log(error.response)
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
                sendNotification(message, 'error')
                setLoading(false)
                setProgress(0)
            })
            // setText('Some text')
            // setConverted(true)
        }
    }

    const handleSelect = (event, newValue) => {
        setLanguage(newValue);
        // if (newValue === null) {
        //     setBtnDisabled(true)
        // }
        // else {
        //     if (file === '') {
        //         setBtnDisabled(true)
        //     }
        //     else {
        //         setBtnDisabled(false)
        //     }
        // }
    }

    const progressBar = loading ? (<LinearProgress variant="determinate" value={progress} />) : ('')

    return (
        <div>
            <Typography variant="h1" color="initial"></Typography>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Image Scan</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            {progressBar}
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        // justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} lg={10}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={languages}
                                getOptionLabel={(option) => option.name}
                                value={language}
                                onChange={handleSelect}
                                renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" required />}
                            />
                        </Grid>
                        <Grid item xs={12} lg='auto'>
                            <Button variant="contained" size="large" onClick={submit}>
                                Convert
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <DropzoneArea
                                acceptedFiles={['image/*']}
                                filesLimit={50}
                                showPreviewsInDropzone={false}
                                showPreviews={true}
                                previewText='Selected:'
                                useChipsForPreview={true}
                                showAlerts={['error']}
                                maxFileSize={12000000}
                                alertSnackbarProps={
                                    {
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }
                                    }
                                }
                                dropzoneText={"Drag and drop an image here or click"}
                                onChange={handleFilesChange}
                            />
                        </Grid>
                        {/* {
                            (converted) ? (
                                <>
                                    <Grid item xs={4} lg={1}>
                                        <Button variant="contained" size="large" onClick={copyToClipboard}>
                                            Copy
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} lg={1}>
                                        <Button variant="contained" size="large" onClick={() => {
                                            setText(null)
                                            setConverted(false)
                                        }}>
                                            Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <TextField
                                            fullWidth
                                            label="Extracted Text"
                                            multiline
                                            rows={8}
                                            variant="outlined"
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        />
                                    </Grid>
                                </>
                            ) : null
                        } */}
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default ImageScan
