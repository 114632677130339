import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles'
import mainLogoLight from '../../assets/images/logo/main-logo-light.png'
import mainLogoDark from '../../assets/images/logo/main-logo-dark.png'

import {
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'

import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { URL } from '../../config'
import axios from 'axios'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useSnackbar } from 'notistack'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
                Image To Text Converter&nbsp;
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles();
    const theme = useTheme();
    const [disabled, setDisabled] = useState(false)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    const sendNotification = (message, variant = 'success') => {
        const options = { 
            variant
        }
        enqueueSnackbar(message, options);
    }

    useEffect(() => {
        const token = localStorage.getItem('oeToken')
        if (token !== null) {
            var location = '/dashboard'
            props.history.push(location)
        }
        // eslint-disable-next-line
    }, [])

    const emailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const passwordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setPassword(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)
        const data = {
            email,
            password,
        }
        const url = URL + '/login'
        axios.post(url, data).then(response => {
            const user = response.data
            localStorage.setItem('oeToken', user.token)
            localStorage.setItem('oeUser', JSON.stringify(user))
            var location = '/dashboard'
            props.history.push(location)
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                (process.env.NODE_ENV !== 'production') && console.log(error.response.data.detail);
                message = error.response.data.detail
            } else if (error.request) {
                (process.env.NODE_ENV !== 'production') && console.log(error.request)
            } else {
                (process.env.NODE_ENV !== 'production') && console.log(error)
            }
            setDisabled(false)
            localStorage.clear()
            sendNotification(message, 'error')
        })
    }
    
    const logo = (theme.palette.type === 'light') ? mainLogoDark : mainLogoLight

    const progress = disabled ? (<LinearProgress />) : ('')

    return (
        <div>
            {progress}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt="OCR Converter Logo" />
                    <div className="mt-5 auth-box">
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} onSubmit={onSubmit} >
                            <TextField
                                required={true}
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                variant="outlined"
                                inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                                onChange={emailChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                variant="outlined"
                                inputProps={{ minLength: 6, maxLength: 50 }}
                                onChange={passwordChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={disabled}
                                className={classes.submit}
                            >
                                <VpnKeyIcon fontSize="small" />&nbsp;&nbsp;Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <MLink href="/#/forgot" variant="body2" color="textSecondary">
                                        Forgot password?
                                </MLink>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    )
}

export default Login
