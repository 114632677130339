import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import LogoDark from '../assets/images/logo/logo-dark.png'
import LogoLight from '../assets/images/logo/logo-light.png'

import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Toolbar,
    Tooltip
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'

import { Redirect, Route, Switch } from 'react-router-dom'

import ProfileMenu from './ProfileMenu'
import SideNav from './SideNav'
import Dashboard from '../pages/Dashboard'
import ListUsers from '../pages/settings/users/ListUsers'
import ListRoles from '../pages/settings/roles/ListRoles'
import AddUser from '../pages/settings/users/AddUser'
import UpdateUser from '../pages/settings/users/UpdateUser'
import AddRole from '../pages/settings/roles/AddRole'
import UpdateRole from '../pages/settings/roles/UpdateRole'
import Profile from '../pages/settings/users/profile/Profile'
import Unauthorized from '../pages/authentication/Unauthorized'
import ResetPassword from '../pages/settings/users/ResetPassword'

import ImageScan from '../pages/scans/ImageScan'
import ListScans from '../pages/scans/ListScans'
import ListScanResults from '../pages/scans/ListScanResults'

import axios from 'axios'
import { URL } from '../config'

import { useSnackbar } from 'notistack'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    }
}))

const Layout = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const token = localStorage.getItem('oeToken')
    const operations = JSON.parse(localStorage.getItem('oeOperations'))
    const classes = useStyles()
    const [open, setOpen] = useState(true)

    const sendNotification = (message, variant = 'success') => {
        const options = { 
            variant
        }
        enqueueSnackbar(message, options);
    }

    const getOperations = () => {
        if (token !== null) {
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = URL + '/operations'
            axios.get(url, headerConfig).then(response => {
                const operations = response.data.operations
                localStorage.setItem('oeOperations', JSON.stringify(operations))
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 402) {
                        localStorage.clear()
                        props.history.push('/login')
                    } else {
                        (process.env.NODE_ENV !== 'production') && console.log(error.response)
                    }
                } else if (error.request) {
                    (process.env.NODE_ENV !== 'production') && console.log(error.request)
                } else {
                    (process.env.NODE_ENV !== 'production') && console.log(error)
                }
            })
        }
    }

    useEffect(() => {
        if (token === null) {
            var location = '/login'
            props.history.push(location)
        }
        getOperations()
        // eslint-disable-next-line
    }, [])

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const checkOperations = (operation) => {
        const status = operations.includes(operation)
        if (status === false) {
            const message = 'You don\'t have permission.'
            sendNotification(message, 'error')
        }
        return status
    }

    const lightButton = (props.darkState) ? (<Tooltip title="Light"><Brightness7Icon style={{ color: "white" }} /></Tooltip>) : (<Tooltip title="Dark"><Brightness4Icon style={{ color: "white" }} /></Tooltip>)

    const logo = (props.darkState) ? LogoLight : LogoDark

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container spacing={3}>
                        <Grid item xs={11}></Grid>
                    </Grid>
                    <IconButton onClick={props.handleThemeChange}>
                        {lightButton}
                    </IconButton>
                    <ProfileMenu />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <img src={logo} onClick={handleDrawerClose} alt="Logo" height="64" className="cursor-pointer" />
                <Divider />
                <SideNav />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/profile" component={Profile} />
                    <Route exact path="/dashboard/unauthorized" component={Unauthorized} />

                    <Route exact path="/dashboard/scan/image"
                        render={(props) => {
                            var status = checkOperations('Image Scan')
                            if (status === true) {
                                return (<ImageScan {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/batch"
                        render={(props) => {
                            var status = checkOperations('List Scan')
                            if (status === true) {
                                return (<ListScans {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/batch/:batchId"
                        render={(props) => {
                            var status = checkOperations('View Scan')
                            if (status === true) {
                                return (<ListScanResults {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    {/* <Route exact path="/dashboard/customers-new"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<ListNewCustomer {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId"
                        render={(props) => {
                            var status = checkOperations('View Customer')
                            if (status === true) {
                                return (<CustomerDetails {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    /> */}

                    <Route exact path="/dashboard/settings/users"
                        render={(props) => {
                            var status = checkOperations('List Users')
                            if (status === true) {
                                return (<ListUsers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/add"
                        render={(props) => {
                            var status = checkOperations('Add User')
                            if (status === true) {
                                return (<AddUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/:userId"
                        render={(props) => {
                            var status = checkOperations('Update User')
                            if (status === true) {
                                return (<UpdateUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/reset-password/:userId"
                        render={(props) => {
                            var status = checkOperations('Reset Password')
                            if (status === true) {
                                return (<ResetPassword {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles"
                        render={(props) => {
                            var status = checkOperations('List Roles')
                            if (status === true) {
                                return (<ListRoles {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/add"
                        render={(props) => {
                            var status = checkOperations('Add Role')
                            if (status === true) {
                                return (<AddRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/:roleId"
                        render={(props) => {
                            var status = checkOperations('Update Role')
                            if (status === true) {
                                return (<UpdateRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                </Switch>
            </main>
        </div>
    )
}

export default Layout
