import moment from 'moment'

export function dateToString(date) {
    return date.format('yyyy-MM-DD')
}

export function stringToDate(date) {
    return moment(date, 'yyyy-MM-DD')
}

export function stringToDateTime(date) {
    return moment(date, 'yyyy-MM-DD hh:mm:ss')
}

export function dateFormate(date) {
    const mDate = stringToDate(date)
    return mDate.format('DD/MM/yyyy')
}

export function dateTimeFormate(date) {
    const mDate = stringToDateTime(date)
    return mDate.format('DD/MM/yyyy hh:mm A')
}

export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
